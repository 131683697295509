const img = '';

export default [
  {
    img: img,
    title: 'The First Encounter',
    description: `Back in August 2017, Gus still clearly remembers Jul walking into the office and being mesmerized by her smile. Right there and then, Gus knew he wanted to know Jul on a deeper level. They quickly became fond of each other through sharing stories and jokes with one another.`,
  },
  {
    img: img,
    title: 'The Journey',
    description: `In 2018, They started chatting more intensely about work and everything else in between. Jul always remembers how helpful Gus has always been to her, from the simplest matter to the most complicated ones. However, both Gus and Jul grew apart and ventured on their own way until one day, fate brought them together again. It was clear that they are meant to be together and they finally dated in October 2020.`,
  },
  {
    img: img,
    title: 'The Wedding',
    description: `They went through lots of ups and downs together but they always have each other’s back and always have the same vision at heart. Jul feels very blessed that Gus is such a gentleman that treats and respects her well. Gus is always in awe of Jul’s sincere that he rarely sees in other people. Through the many things that happened, Gus always <i><b>#GUidingJULhome</b></i> and their love grew stronger and always feel right at home when they are with each other. To kick start their journey to forever, they have decided to tie the knot on November 23, 2023 and are looking forward to celebrating with you all!`,
  },
];
